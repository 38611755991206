
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { format as formatCPF } from "@/utils/cpf";
import DatePicker from "@/components/DatePicker.vue";
import { CtpsProposal } from "@/types/ctps-proposal";
import BorrowerService from "@/services/borrower-service";
import SafetyService from "@/services/safety-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanService from "@/services/loan-service";

@Component({
  components: { ValidationObserver, DatePicker }
})
export default class ConfirmBorrowerPhoneModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: CtpsProposal;
  isLoading: boolean = false;
  formatCPF = formatCPF;
  telefone: string | null = null;

  borrowerService: BorrowerService;
  safetyService: SafetyService;
  loanService: LoanService;

  constructor() {
    super();
    this.borrowerService = BorrowerService.getInstance();
    this.safetyService = SafetyService.getInstance();
    this.loanService = LoanService.getInstance();
  }

  async continueProposal() {
    const [borrowerError, newBorrower] =
      await this.safetyService.signupCtpsBorrower({
        cpf: this.item.cpf,
        name: this.item.workerName,
        phone: this.telefone,
        email: "",
        password: this.item.cpf
      });

    if (borrowerError) {
      this.isLoading = false;
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(borrowerError)
      });
    }

    const [loanError, loan] = await this.loanService.continueCtpsProposal(
      this.item.proposalRequestId
    );

    if (loanError) {
      this.isLoading = false;
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(loanError)
      });
    }

    this.$router.push({
      name: "Loans",
      query: { loanId: String(loan.id) }
    });
    this.isLoading = false;
  }
}
