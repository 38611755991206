
import FileUpload from "@/components/FileUpload.vue";
import Modal from "@/components/Modal.vue";
import StatusHistory from "@/components/loans/StatusHistory.vue";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import formatDate from "@/utils/formatDate";
import BankAcountModal from "@/components/loans/BankAcountModal.vue";
import LoanService from "../../services/loan-service";
import getErrorMessageFromApiError from "../../utils/getErrorMessageFromApiError";
import formatCurrency from "@/utils/formatCurrency";
import { CtpsProposal } from "@/types/ctps-proposal";

@Component({
  components: {
    ValidationObserver,
    Modal,
    FileUpload,
    StatusHistory,
    BankAcountModal
  }
})
export default class CtpsProposalModal extends Vue {
  @Prop() readonly item!: CtpsProposal;
  loadingAdditionalProposalData: boolean = false;
  dadosProcessados: Record<string, unknown> | null = null;
  columns: string[] = [
    "requestedValue",
    "installmentValue",
    "numInstallments",
    "total",
    "iofValue",
    "annualInterestRate",
    "monthlyInterestRate",
    "annualCetRate",
    "monthlyCetRate",
    "recorded"
  ];
  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  formatDate = formatDate;
  formatCurrency = formatCurrency;
  loanService: LoanService;

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
  }

  async mounted(): Promise<void> {
    await this.fetchProposalAdditionalData();
  }

  async fetchProposalAdditionalData(): Promise<void> {
    this.loadingAdditionalProposalData = true;

    const [error, loanAdditionalData] =
      await this.loanService.listCtpsProposals({
        proposalId: this.item.proposalId,
        page: 1,
        limit: 1,
        columns: this.columns.join(",")
      });

    if (error || !loanAdditionalData) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }

    if (loanAdditionalData.items.length) {
      const [additionalData] = loanAdditionalData.items;
      const keysToUpdate = [...this.columns];

      keysToUpdate.forEach((key) => {
        if (additionalData[key] !== undefined) {
          this.item[key] = additionalData[key];
        }
      });
    }

    this.loadingAdditionalProposalData = false;
  }

  formatPhoneNumber(phoneNumber: string): string {
    const cleanNumber = phoneNumber.replace("+55", "").replace(/\D+/g, "");

    if (cleanNumber.length === 11) {
      return `(${cleanNumber.slice(0, 2)}) ${cleanNumber.slice(
        2,
        7
      )}-${cleanNumber.slice(7, 11)}`;
    } else if (cleanNumber.length === 10) {
      return `(${cleanNumber.slice(0, 2)}) ${cleanNumber.slice(
        2,
        6
      )}-${cleanNumber.slice(6, 10)}`;
    } else {
      return phoneNumber;
    }
  }

  parsePhones(phones: string): string[] {
    try {
      return JSON.parse(phones);
    } catch (e) {
      return [];
    }
  }

  close(): void {
    this.$emit("close");
  }
}
