
import { Vue, Component } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import LoanService from "@/services/loan-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanInstallment from "@/types/loan-installment";
import { CtpsProposal } from "@/types/ctps-proposal";
import ListCtpsProposalsParams from "@/services/loan-service/types/list-ctps-proposals-params";
import CtpsProposalsTable from "@/components/ctps-proposals/CtpsProposalsTable.vue";
import CtpsProposalModal from "@/components/ctps-proposals/CtpsProposalModal.vue";
import ConfirmBorrowerPhoneModal from "@/components/ctps-proposals/ConfirmBorrowerPhoneModal.vue";

@Component({
  components: {
    Page,
    CtpsProposalsTable,
    CtpsProposalModal,
    ConfirmBorrowerPhoneModal
  }
})
export default class CtpsProposals extends Vue {
  service: LoanService;
  ctpsProposals: { items: Array<CtpsProposal>; total: number };
  ctpsProposal: CtpsProposal | null;
  loanInstallment: LoanInstallment | null;
  refreshLoans: number = 0;
  showProposalModal: boolean;
  showConfirmBorrowerPhoneModal: boolean;

  defaultReturn = { items: [], total: 0 };

  constructor() {
    super();

    this.service = LoanService.getInstance();
    this.ctpsProposals = { ...this.defaultReturn };
    this.ctpsProposal = null;
    this.showProposalModal = false;
    this.showConfirmBorrowerPhoneModal = false;
  }

  showProposal(item: CtpsProposal): void {
    this.showProposalModal = true;
    this.ctpsProposal = { ...item };
  }

  showUpdateBorrowerPhone(item: CtpsProposal): void {
    this.showConfirmBorrowerPhoneModal = true;
    this.ctpsProposal = { ...item };
  }

  closeBorrowerModal(): void {
    this.ctpsProposal = null;
    this.showConfirmBorrowerPhoneModal = false;
  }

  closeProposal(): void {
    this.ctpsProposal = null;
    this.showProposalModal = false;
  }

  async fetchCtpsProposals(options: ListCtpsProposalsParams): Promise<void> {
    const {
      proposalId,
      proposalIds,
      cpf,
      cnpj,
      loanId,
      requestDateStart,
      requestDateEnd,
      page,
      limit,
      expired,
      endorsedProposals,
      columns
    } = options;

    let [err, proposalsData] = await this.service.listCtpsProposals({
      proposalId,
      proposalIds,
      cpf,
      cnpj,
      loanId,
      requestDateStart,
      requestDateEnd,
      page,
      limit,
      expired,
      endorsedProposals,
      columns
    });

    if (!err) {
      this.ctpsProposals = proposalsData!;
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Propostas CTPS", type: "error", text: message });
    }
  }
}
