
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import { format as formatDocument } from "@/utils/cpfOrCnpj";
import formatDate from "@/utils/formatDate";
import DatePicker from "@/components/DatePicker.vue";
import formatDatetime from "../../utils/formatDatetime";
import dayjs from "dayjs";
import SelectCompany from "@/components/companies/SelectCompany.vue";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { CtpsProposal } from "@/types/ctps-proposal";
import ListCtpsProposalsParams from "@/services/loan-service/types/list-ctps-proposals-params";
import BorrowerService from "@/services/borrower-service";
import LoanService from "@/services/loan-service";

interface Filters {
  cnpj?: string | null;
  cpf?: string | null;
  loanId?: number | null;
  requestDateStart?: string | null;
  requestDateEnd?: string | null;
  expired: boolean;
  endorsedProposals: boolean;
  columns?: string[];
}

@Component({
  components: {
    DatePicker,
    SelectCompany
  }
})
export default class CtpsProposalsTable extends Vue {
  @Prop({ default: [] }) readonly ctpsProposals!: {
    items: Array<CtpsProposal>;
    total: number;
  };
  @Prop() fetch!: (options: ListCtpsProposalsParams) => Promise<void>;
  @Prop() refresh!: number;

  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  formatDocument = formatDocument;
  formatDate = formatDate;
  formatDatetime = formatDatetime;

  borrowerService: BorrowerService;
  loanService: LoanService;

  continueLoanCpf: string;
  options: DataOptions;
  headers: Array<DataTableHeader>;
  selectedHeaders: Array<DataTableHeader> = [];
  loading: boolean;
  columns: string[] = [
    "loanId",
    "proposalId",
    "companyName",
    "cpf",
    "cnpj",
    "requestDate",
    "proposalExpirationDate",
    "continueProposalSmsSent",
    "lastSmsContinueProposalDate",
    "phones",
    "workerName",
    "isProposalEndorsed",
    "requestDate",
    "proposalRequestId"
  ];
  filters: Filters = {
    cnpj: null,
    cpf: null,
    loanId: null,
    requestDateStart: null,
    requestDateEnd: null,
    columns: this.columns,
    expired: false,
    endorsedProposals: false
  };
  dateFilterTypes: Array<string> = ["Data da solicitação"];
  dateFilterType = "Data da solicitação";
  borrowers: Array<{ id: number; name: string }> | null = null;
  fileManagerUrl: string;
  showGridOptions: boolean = false;

  constructor() {
    super();

    this.continueLoanCpf = "";
    this.headers = [
      { text: "Empréstimo", value: "loanId", width: "5%" },
      { text: "Proposta", value: "proposalId", width: "5%" },
      { text: "Empresa", value: "companyName", width: "15%" },
      {
        text: "Trabalhador",
        value: "workerName",
        sortable: false,
        width: "10%"
      },
      {
        text: "Tempo de expiração",
        value: "proposalExpirationDate",
        sortable: false,
        width: "5%",
        align: "center"
      },
      {
        text: "Data solicitação",
        value: "requestDate",
        sortable: false,
        width: "5%",
        align: "center"
      },
      {
        text: "Último envio SMS",
        value: "lastSmsContinueProposalDate",
        sortable: false,
        width: "5%",
        align: "center"
      },
      {
        text: "Averbada",
        value: "isProposalEndorsed",
        sortable: false,
        width: "5%",
        align: "center"
      },
      {
        text: "Telefones",
        value: "phones",
        sortable: false,
        width: "10%",
        align: "center"
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        width: "5%",
        align: "center"
      }
    ];
    this.loading = false;
    this.options = {} as DataOptions;
    this.fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
    this.borrowerService = BorrowerService.getInstance();
    this.loanService = LoanService.getInstance();
  }

  mounted() {
    const notDefaultHeaders = ["partner"];
    this.selectedHeaders = this.headers.filter(
      (h) => !notDefaultHeaders.includes(h.value)
    );
  }

  @Watch("options")
  onOptionsChange(val: DataOptions) {
    this.fetchCtpsProposals(val);
  }

  get selectedHeadersList() {
    return this.headers.filter((h) => this.selectedHeaders.includes(h));
  }

  async fetchCtpsProposals(val: DataOptions) {
    const { page, itemsPerPage, sortBy, sortDesc } = val;
    const {
      columns,
      cpf,
      cnpj,
      loanId,
      requestDateStart,
      requestDateEnd,
      expired,
      endorsedProposals
    } = this.filters;

    this.startLoading();
    await this.fetch({
      page: page,
      limit: itemsPerPage,
      columns: columns.join(","),
      cpf,
      cnpj,
      loanId,
      requestDateStart,
      requestDateEnd,
      expired,
      endorsedProposals
    });
    this.stopLoading();
  }

  @Watch("refresh")
  onRefreshChange() {
    this.options = { ...this.options };
  }

  applyFilter() {
    this.options.page = 1;
    this.fetchCtpsProposals(this.options);
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  formatPhoneNumber(phoneNumber: string): string {
    const cleanNumber = phoneNumber.replace('+55', '').replace(/\D+/g, "");

    if (cleanNumber.length === 11) {
      return `(${cleanNumber.slice(0, 2)}) ${cleanNumber.slice(
        2,
        7
      )}-${cleanNumber.slice(7, 11)}`;
    } else if (cleanNumber.length === 10) {
      return `(${cleanNumber.slice(0, 2)}) ${cleanNumber.slice(
        2,
        6
      )}-${cleanNumber.slice(6, 10)}`;
    } else {
      return phoneNumber;
    }
  }

  emitShow(item: CtpsProposal) {
    this.$emit("show", item);
  }

  async continueProposal(item: CtpsProposal) {
    if (item.loanId) {
      this.$router.push({
        name: "Loans",
        query: { loanId: String(item.loanId) }
      });
      return;
    }

    const [borrowerError, borrower] =
      await this.borrowerService.getBorrowerByCpf(item.cpf);

    if (!borrower) {
      this.$emit("confirmPhone", item);
      return;
    }

    const [loanError, loan] = await this.loanService.continueCtpsProposal(
      item.proposalRequestId
    );

    if (loanError) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(loanError)
      });
    }

    this.$router.push({
      name: "Loans",
      query: { loanId: String(loan.id) }
    });
  }

  isExpiredProposal(date: string): boolean {
    return dayjs().isAfter(date);
  }

  
  
    getHoursMinutesUntilExpiration(date: string): string {
    const expirationDate = dayjs(date);
    const now = dayjs();
    const diffInMs = expirationDate.diff(now);

    if (diffInMs <= 0) {
      return '0h 0m';
    }

    const hours = Math.floor(diffInMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
    }

  getHoursUntilExpiration(date: string): number {
    const expirationDate = dayjs(date);
    const now = dayjs();
    const diffInMs = expirationDate.diff(now);

    if (diffInMs <= 0) {
      return 0;
    }

    return Math.floor(diffInMs / (1000 * 60 * 60));
  }

  getBackgroundColor(date: string): string {
    const hoursUntilExpiration = this.getHoursUntilExpiration(date);

    if (hoursUntilExpiration <= 0) {
      return "#ffd2d2";
    } else if (hoursUntilExpiration <= 6) {
      return "#ffd2d2";
    } else if (hoursUntilExpiration <= 12) {
      return "#ffe6cc";
    } else if (hoursUntilExpiration <= 24) {
      return "#ffffe0";
    } else {
      return "#d9f2d9";
    }
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }

  parsePhones(phones: string): string[] {
    try {
      return JSON.parse(phones);
    } catch (e) {
      return [];
    }
  }
}
